import perspective from "@finos/perspective";

import "@finos/perspective-viewer";
import "@finos/perspective-workspace";
import "@finos/perspective-viewer-datagrid";
//import "@finos/perspective-viewer-d3fc/bar";
//import "@finos/perspective-viewer-d3fc";

import "@finos/perspective-viewer/dist/css/themes.css";

import "./index.css";
import { CustomDatagridPlugin } from "./custom_datagrid"

const worker = perspective.shared_worker();
import DEFAULT_LAYOUT from "./layout.json";

import ccxt from "ccxt";
var width = 1;

async function call_promise_increment(promise, element, increment) {
    const value = await promise;
    width += increment;
    element.style.width = width + "%";
    element.innerHTML = width.toFixed(2) + "%...";
    return value;
}

async function fetchBybitPerpTickerData() {
        var loading_bar = document.getElementById("myBar");
        
	const exchange = new ccxt.okx();
	// Load markets
	console.log("loading markets");
	await exchange.loadMarkets();

	// Filter only perpetual contracts
	const markets = await exchange.fetchMarkets();
        // get length of markets to use to determine loading bar increment
        var market_increment = 98 / (markets.length / 4);
        width += 2
        loading_bar.style.width = width + "%";

	// Filter perpetual contracts
	const perps = markets.filter((market) => market.swap && market.settle === 'USDT');

 	 // Create an array to hold the promises for each API request
  	const promises = [];
        const current_rates = [];
        const maxLeverage = [];

  	// Enable rateLimit to prevent API rate limiting issues
  	exchange.rateLimit = 500;

	// Fetch funding rates for each symbol
	for (const symbol of perps) {
                maxLeverage.push(symbol.info.lever);
		promises.push(call_promise_increment(exchange.fetchFundingRateHistory(symbol.symbol), loading_bar, market_increment));
                current_rates.push(call_promise_increment(exchange.fetchFundingRate(symbol.symbol), loading_bar, market_increment));
                //current_rates.push(exchange.fetchFundingRate(symbol.symbol));
	}

  	// Wait for all promises to resolve
  	const results = await Promise.all(promises);
        const current_funding_rates = await Promise.all(current_rates);
        console.log("current_funding_rates");
        console.log(current_funding_rates);
	const funding_24 = [];
	const funding_3d = [];
	const funding_7d = [];
	const funding_30d = [];
	// Process the results
	for (let i = 0; i < results.length; i++) {
		const symbol = perps[i];
		const funding_rates = results[i];
		const current_time = Date.now();

		let rate24 = 0;
		let rate3d = 0;
		let rate7d = 0;
		let rate30d = 0;

		for (let i = funding_rates.length - 1; i >= 0; i--) {
			const rate = funding_rates[i];
			const timeDiff = current_time - rate.timestamp;

			if (timeDiff < 24 * 60 * 60 * 1000) {
				rate24 += parseFloat(rate.fundingRate);
				rate3d += parseFloat(rate.fundingRate);
				rate7d += parseFloat(rate.fundingRate);
				rate30d += parseFloat(rate.fundingRate);
			} else if (timeDiff < 3 * 24 * 60 * 60 * 1000) {
				rate3d += parseFloat(rate.fundingRate);
				rate7d += parseFloat(rate.fundingRate);
				rate30d += parseFloat(rate.fundingRate);
			} else if (timeDiff < 7 * 24 * 60 * 60 * 1000) {
				rate7d += parseFloat(rate.fundingRate);
				rate30d += parseFloat(rate.fundingRate);
			} else if (timeDiff < 30 * 24 * 60 * 60 * 1000) {
				rate30d += parseFloat(rate.fundingRate);
			}
		}

		rate24 = rate24 * 365 * 100;
		rate3d = (rate3d / 3) * 365 * 100;
		rate7d = (rate7d / 7) * 365 * 100;
		rate30d = (rate30d / 30) * 365 * 100;
		funding_24.push(rate24);
		funding_3d.push(rate3d);
		funding_7d.push(rate7d);
		funding_30d.push(rate30d);

		//console.log(`Symbol: ${symbol}, Funding Rate: ${rate30d}`);
	}



	// Create arrays to store the data
	const symbols = [];
	const priceChange24h = [];
	const volume24h = [];
	const funding = [];
	const fundingAnnualised = [];
	const openInterest = [];
	const basis = [];
	const spread = [];
	const tickers = await exchange.fetch_tickers_by_type("swap");
	// Fetch 24-hour price change and volume for each perpetual contract
        var counter = 0;
	for (const perp of perps) {
		const symbol = perp.symbol;
		const base = perp.base;
		const ticker = tickers[symbol];
                console.log(ticker);
                console.log(perp);
		const price_change_24h = ticker.percentage;
		const price_change_1h = ticker.percentage;
		//const index_price = parseFloat(ticker.info.indexPrice);
                //above does not work for okex make new call 
                
		const mark_price = parseFloat(ticker.info.markPrice);
		const funding_interval = ((current_funding_rates[counter].nextFundingTimestamp - current_funding_rates[counter].fundingTimestamp) / (60 * 60 * 1000)); 
		const bid_price = parseFloat(ticker.bid);
		const ask_price = parseFloat(ticker.ask);
		const mid_price = (bid_price + ask_price) / 2;
		const spread_val = ((ask_price - bid_price) * 100) / mid_price;
		const basis_val = parseFloat(current_funding_rates[counter].info.premium) * 100;
		const volume_24h = ticker.baseVolume * ticker.last * perp.contractSize;
                
		//const max_lev = perp.info.leverageFilter.maxLeverage;
		const funding_val = parseFloat(current_funding_rates[counter].fundingRate) * 100; //parseFloat(await exchange.fetch_funding_rate(symbol)) * 100;
                counter += 1;
		const funding_annualised = funding_val * (365 * 24) / funding_interval;
		const oi = ticker.info.openInterestValue;
		
		//var content = '<a href="https://opensea.io/CoinUnited" target="blank" class="data-permalink">'+ base+ '</a>';         
		symbols.push(base);
		priceChange24h.push(parseFloat(price_change_24h));
		volume24h.push(parseFloat(volume_24h));
		//maxLeverage.push(parseFloat(max_lev));
		funding.push(parseFloat(funding_val));
		fundingAnnualised.push(parseFloat(funding_annualised));
		openInterest.push(parseFloat(oi));
		basis.push(parseFloat(basis_val));
		spread.push(parseFloat(spread_val));
	}

	  const rows = [];
	  for (let i = 0; i < symbols.length; i++) {
		const row = [
		  symbols[i],
		  priceChange24h[i],
		  volume24h[i],
		  maxLeverage[i],
		  funding[i],
		  fundingAnnualised[i],
		  funding_24[i],
		  funding_3d[i],
		  funding_7d[i],
		  funding_30d[i],
		  openInterest[i],
		  basis[i],
		  spread[i]
		];
		rows.push(row.join(','));
	  }
	  const header = 'Symbol,Price Change 24h,Volume 24h,Max Leverage,Funding,Current Funding Annualised, Funding 24hr avg., Funding 3d avg.,Funding 7d avg.,Funding 30d avg.,Open Interest,Basis,Spread';
	  // "plugin_config": {"columns":{"Symbol":{"format":"link"}}},
	  // Create the CSV string
	  //const csvString = `${header}\n${rows.join('\n')}`;
         

          
	  const data = [];

		for (let i = 0; i < symbols.length; i++) {
			const row = {
				Symbol: symbols[i],
				'Price Change 24h': parseFloat(priceChange24h[i]),
				Funding: parseFloat(funding[i]),
				'Current Funding Annualised': parseFloat(fundingAnnualised[i]),
				'Funding 24hr avg.': funding_24[i], // Add corresponding data for 'funding_24' if available
				'Funding 3d avg.': funding_3d[i], // Add corresponding data for 'funding_3d' if available
				'Funding 7d avg.': funding_7d[i], // Add corresponding data for 'funding_7d' if available
				'Funding 30d avg.': funding_30d[i], // Add corresponding data for 'funding_30d' if available
				Basis: parseFloat(basis[i]),
				Spread: parseFloat(spread[i]),
				'Volume 24h': parseFloat(volume24h[i]),
				'Max Leverage': parseFloat(maxLeverage[i]),
				'Open Interest': parseFloat(openInterest[i])
			};

			data.push(row);
		}

		const jsonData = JSON.stringify(data);

		console.log(jsonData);
        loading_bar.style.width = "100%";
        loading_bar.innerHTML = "Finished Loading!";
	try {
	// Return JSON data
	return data;
	} catch (error) {
	console.error('Error fetching Bybit ticker data:', error);
	throw error;
	}
}

window.addEventListener("DOMContentLoaded", async () => {
	//let dt = await datasource();
	const table = await fetchBybitPerpTickerData();
	console.log(table);
	//const worker = perspective.worker();

	const tbl = worker.table(table);
	//const viewer = document.createElement("perspective-viewer");
    //document.body.append(viewer);

    //viewer.load(tbl);
    //window.viewer = viewer;
	
	// Loop through the data and populate the table rows

	//const workspace = document.querySelector("perspective-workspace");
	window.workspace.restore(DEFAULT_LAYOUT);


	customElements.define(
		"perspective-viewer-custom-datagrid",
		CustomDatagridPlugin
	);

	customElements.get("perspective-viewer").registerPlugin("perspective-viewer-custom-datagrid");

	window.workspace.addTable("main", tbl);
	//window.workspace.tables.set("main", tbl);

	//const viewer = document.getElementsByTagName('perspective-viewer-custom-datagrid')[0];
	//const data = await fetch("https://cdn.jsdelivr.net/npm/superstore-arrow/superstore.arrow");
	//const arr = await data.arrayBuffer();
	//const table1 = perspective.worker().table(arr.slice());
	//viewer.load(tbl);
	//viewer.restore({
	//	plugin: "Custom Datagrid",
		//columns: ["Profit","Sub-Category","State","Sales","Category","Order Date"]
	//	});
	//viewer.toggleConfig();
    //const viewer = document.createElement("perspective-viewer");
	//wiondow.workspace.tables.set("")
    //document.body.append(viewer);
	//const worker = perspective.worker();
   
    //const table = await fetchBybitPerpTickerData();
	//const tbl = worker.table(table);

    //const table = worker.table(superstore);
    //viewer.load(tbl);
    //window.viewer = viewer;
	//            "plugin_config":{"columns":{"Basis":{"column_size_override":129,"fixed":4,"neg_fg_color":"#ff471e","pos_fg_color":"#000000"},"Current Funding Annualised":{"column_size_override":220.234,"neg_fg_color":"#ff471e","pos_fg_color":"#000000"},"Funding":{"column_size_override":111.422,"fixed":4,"neg_fg_color":"#ff471e","pos_fg_color":"#000000"},"Funding 24hr avg.":{"column_size_override":166.422,"neg_fg_color":"#ff471e","pos_fg_color":"#000000"},"Funding 30d avg.":{"column_size_override":177.219,"neg_fg_color":"#ff471e","pos_fg_color":"#000000"},"Funding 3d avg.":{"column_size_override":164.016,"neg_fg_color":"#ff471e","pos_fg_color":"#000000"},"Funding 7d avg.":{"column_size_override":152.016,"neg_fg_color":"#ff471e","pos_fg_color":"#000000"},"Max Leverage":{"column_size_override":121.422,"fixed":1,"number_fg_mode":"disabled"},"Open Interest":{"column_size_override":128.219,"fixed":0,"number_fg_mode":"disabled"},"Price Change 24h":{"column_size_override":176.219,"fixed":3,"neg_fg_color":"#ff471e","pos_fg_color":"#000000"},"Spread":{"column_size_override":137.219,"fixed":4,"neg_fg_color":"#ff471e","pos_fg_color":"#000000"},"Volume 24h":{"column_size_override":128.625,"fixed":0,"number_fg_mode":"disabled"}}},

});
