

function hue(value, min, max) {
    const norm =
        "0" +
        Math.abs(
            Math.round(
                255 * (Math.min(Math.max(value, min), max) / (max - min))
            )
        ).toString(16);
    return norm.slice(norm.length - 2, norm.length);
}

function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL("image/png");
}

function make_symbol(td, metadata, cache, clean_name) {
    td.style.background = "";
    td.style.border = ``;
    if (cache[clean_name] && cache[clean_name].length > 0) {
        const name = metadata.value;
        td.textContent = "";
        td.appendChild(cache[name].pop());
    } else {
        const name = metadata.value;
        const span = document.createElement("span");
        const img = document.createElement("img");
        img.onload = () => {
            img.onload = undefined;
            const data = getBase64Image(img);
            img.src = data;
        };
        img.setAttribute("crossorigin", "anonymous");
        img.setAttribute(
            "src",
            `https://www.bybit.com/bycsi-root/fop/79506b06-27cf-48cb-9607-0803e6f7d1e9.svg`
        );

        td.textContent = "";
        span.appendChild(img);
        td.appendChild(span);
        CACHE[name] = CACHE[name] || [];
        CACHE[name].push(img);
    }
}
function make_symbol2(td, metadata, cache, clean_name) {
    td.style.background = "";
    td.style.border = ``;
  
    const name = metadata.value;
    const link = document.createElement("a");
    link.href = `https://www.okx.com/trade-swap/${name.toLowerCase()}-usdt-swap?channelId=37698785`;
    link.target= "_blank";
  
    link.textContent = name;
    td.textContent = "";
    td.appendChild(link);
  }

function make_symbol3(td, metadata) {
    td.style.background = "";
    td.style.border = ``;
  
    const n = metadata.value;

    td.textContent = `${n}%`;
    //td.appendChild(link);
  }

function make_symbol4(td, metadata) {
    td.style.background = "";
    td.style.border = ``;
  
    const n = metadata.value;

    td.textContent = `${n}x`;
    //td.appendChild(link);
  }
function make_clear(td) {
    td.style.border = ``;
    td.style.background = "";
    td.style.color = "";
}
const CACHE = {};
function clone_img_cache() {
    return Object.keys(CACHE).reduce((obj, key) => {
        obj[key] = CACHE[key].slice();
        return obj;
    }, {});
}

export class CustomDatagridPlugin extends customElements.get(
    "perspective-viewer-datagrid"
) {
    get name() {
        return "Custom Datagrid";
    }

    async styleListener() {
        const viewer = this.parentElement;
        const datagrid = this.regular_table;
        if (this._dirty) {
            await this.refresh_cache();
        }

        const cache = clone_img_cache();
        for (const td of datagrid.querySelectorAll("td")) {
            const metadata = datagrid.getMeta(td);
                
            let type;
            if (metadata.x >= 0) {
                const column_path = this._column_paths[metadata.x];
                //console.log("column path");
                //console.log(column_path);
                if (column_path == 'Symbol') {
                    const clean_name = metadata.value && metadata.value.trim && metadata.value.trim();
                    make_symbol2(td, metadata, cache, clean_name);
                }
                if (column_path.includes("Funding") || column_path.includes("Change") || column_path.includes("Spread") || column_path.includes("Basis")) {
                    const clean_name = metadata.value && metadata.value.trim && metadata.value.trim();
                    make_symbol3(td, metadata);
                }
                if (column_path == 'Max Leverage') {
                    const clean_name = metadata.value && metadata.value.trim && metadata.value.trim();
                    make_symbol4(td, metadata);
                }
                const column_path_parts = column_path.split("|");
                type =
                    this._schema[
                        column_path_parts[column_path_parts.length - 1]
                    ];
            } else {
                const column_path = this._group_by[metadata.row_header_x - 1];
                type = this._table_schema[column_path];
            }
            const clean_name =
                metadata.value && metadata.value.trim && metadata.value.trim();
            td.classList.toggle(
                "orbitron",
                type === "integer" || type === "float"
            );
            //if (type === "float") {
            //    make_led_cell(td, metadata);
            //} else if (clean_name in states) {
            //    make_flag(td, metadata, cache, clean_name);
            //} else {
            //    make_clear(td);
            //}
        }
    }

    async refresh_cache() {
        const view = this._view;
        this._column_paths = await view.column_paths();
        this._group_by = await view.get_config()["group_by"];
        this._schema = await view.schema();
        this._dirty = false;
    }

    async activate(view) {
        await super.activate(view);
        this._view = view;
        this._dirty = true;
        if (!this._custom_initialized) {
            const viewer = this.parentElement;
            const datagrid = this.regular_table;
            this._max = -Infinity;
            await this.refresh_cache(view);
            const table = await viewer.getTable(true);
            this._table_schema = await table.schema();
            viewer.addEventListener("perspective-config-update", async () => {
                this._max = -Infinity;
                this._dirty = true;
            });

            this._custom_initialized = true;
            datagrid.addStyleListener(this.styleListener.bind(this));
        }
    }
}
